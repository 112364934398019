import { enableProdMode, StaticProvider } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { createElementsClass } from "@vattenfall/core";
import { environment } from "src/environments/environment";

if (environment.production) {
  enableProdMode();
}

// Callback function invoked once static providers has been resolved
async function onStaticProvidersResolved(staticProviders: StaticProvider[]) {
  const bootstrapModule = await import("./app/app.module").then((m) => m.AppModule);
  return platformBrowserDynamic(staticProviders).bootstrapModule(bootstrapModule);
}

// Setting the class to a property of the window object makes it
// accessable from outside this module
(window as any)["Elements"] = createElementsClass<unknown>(onStaticProvidersResolved, environment.production);
